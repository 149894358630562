<template>
  <div class="col-12">
    <div class="card">
      <h5>Ver Detalle de Cliente: {{ cliente.nombre }}</h5>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-9">
          <label for="tipo">TIPO CLIENTE</label>
          <InputText id="tipo" v-model="cliente.tipo" disabled />
        </div>
        <div class="field col-12 md:col-9">
          <label for="nombre">Nombre Completo</label>
          <InputText id="nombre" v-model="cliente.nombre" disabled />
        </div>
        <div class="field col-12 md:col-3">
          <label for="telefono">Telefono</label>
          <InputText id="telefono" v-model="cliente.telefono" disabled />
        </div>
        <div class="field col-12 md:col-5">
          <label for="ci_nit"><strong>Tipo doc. Identidad: </strong></label>
          <InputText
            id="telefono"
            v-model="cliente.texto_tipo_documento"
            disabled
          />
        </div>
        <div class="field col-12 md:col-3">
          <label for="contactos">CONTACTO</label>
          <InputText id="contactos" v-model="cliente.contactos" disabled />
        </div>
        <div class="field col-11 md:col-3">
          <label for="ci_nit">CI/NIT</label>
          <InputText id="ci_nit" v-model="cliente.ci_nit" disabled />
        </div>
        <div class="field col-1 md:col-1">
          <label for="ci_nit"><strong>Ext. </strong></label>
          <InputText id="ci_nit" v-model="cliente.complemento_documento" disabled />
         
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6">
          <label for="domicilio">Domicilio</label>
          <Textarea id="domicilio" v-model="cliente.domicilio" disabled />
        </div>
        <div class="field col-12 md:col-6">
          <label for="correo">Correo Electronico</label>
          <InputText id="correo" v-model="cliente.correo" disabled />
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <label for="estado">Estado</label>
          <InputText id="estado" v-model="texto_estado" disabled />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Fecha Creación</label>
          <InputText type="text" disabled v-model="cliente.created_at" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Fecha Actualización</label>
          <InputText type="text" disabled v-model="cliente.updated_at" />
        </div>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12">
            <Button @click="goBack">
              <i class="pi pi-arrow-circle-left"></i>
              &nbsp;ATRAS
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClienteService from "@/service/ClienteService";

export default {
  data() {
    return {
      cliente: {},
      idCliente: this.$route.params.id,
      texto_estado: "",
    };
  },
  clienteService: null,
  created() {
    this.clienteService = new ClienteService();
  },
  mounted() {
    this.clienteService.showCliente(this.idCliente).then((data) => {
      this.cliente = data;
      this.texto_estado = this.textEstado(data.estado);
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    textEstado(estado) {
      return estado == 1 ? "Activo" : "Inactivo";
    },
  },
};
</script>
